module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[],"showInProduction":false,"axeOptions":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kids First","short_name":"Kids First","description":"Kids First Australia","start_url":"/","background_color":"#303246","theme_color":"#303246","display":"minimal-ui","icon":"src/images/icons/icon_32x32.png","icons":[{"src":"src/images/icons/icon_16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/icons/icon_32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/icons/icon_48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/images/icons/icon_70x70.png","sizes":"70x70","type":"image/png"},{"src":"src/images/icons/icon_96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/icons/icon_120x120.png","sizes":"120x120","type":"image/png"},{"src":"src/images/icons/icon_128x128.png","sizes":"128x128","type":"image/png"},{"src":"src/images/icons/icon_167x167.png","sizes":"167x167","type":"image/png"},{"src":"src/images/icons/icon_180x180.png","sizes":"180x180","type":"image/png"},{"src":"src/images/icons/icon_196x196.png","sizes":"196x196","type":"image/png"},{"src":"src/images/icons/icon_270x270.png","sizes":"270x270","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bbbbf5f8e7a50b4b6b76eaeaadd1c227"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
