exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-child-and-family-services-tsx": () => import("./../../../src/pages/child-and-family-services.tsx" /* webpackChunkName: "component---src-pages-child-and-family-services-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-need-urgent-help-tsx": () => import("./../../../src/pages/need-urgent-help.tsx" /* webpackChunkName: "component---src-pages-need-urgent-help-tsx" */),
  "component---src-pages-news-media-tsx": () => import("./../../../src/pages/news-media.tsx" /* webpackChunkName: "component---src-pages-news-media-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../../../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */),
  "component---src-pages-support-us-tsx": () => import("./../../../src/pages/support-us.tsx" /* webpackChunkName: "component---src-pages-support-us-tsx" */),
  "component---src-templates-article-pages-tsx": () => import("./../../../src/templates/article-pages.tsx" /* webpackChunkName: "component---src-templates-article-pages-tsx" */),
  "component---src-templates-kinder-pages-tsx": () => import("./../../../src/templates/kinder-pages.tsx" /* webpackChunkName: "component---src-templates-kinder-pages-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

